@import "variables";

.title {
  margin: 30px 0 20px;
}

/*
  Copying button styling here instead of using <Button>
  with internalLink because of getUrlWithRedirect usage
*/
.linkAsButton {
  display: block;
  width: 100%;
  font-weight: 500;
  text-align: center;
  color: #003069;
  padding: 8px 23px;
  background-color: white;
  border: 2px solid #003069;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );
  }
}
