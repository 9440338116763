$pepsi-blue: #003069;
$lightest-blue: #d1eeff;
$dark-gray: #222222;
$med-gray: #666666;
$gray: #999999;
$lightest-gray: #bcbcbc;
$light-gray: #f6f6f6;
$light-green: #00883f;
$light-orange: #e07647;
$red: #a62635;
$brown: #8e6451;
