@import "variables";

.wrapper {
  text-align: center;
}

.logo {
  width: 160px;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 40px 15px 60px 15px;

  @include mobile {
    flex-direction: column;
  }

  .block {
    text-align: left;
  }

  .desc {
    padding-bottom: 30px;
  }

  .block:first-child {
    padding-right: 20px;
    border-right: 2px solid rgba(0, 0, 0, 0.16);
    width: 400px;

    @include mobile {
      width: 100%;
      border: none;
      padding-right: 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.16);
      padding-bottom: 20px;
    }

    .button {
      width: 350px;
      margin: 0 auto;
      padding-top: 5px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .block:last-child {
    padding-left: 20px;
    width: 350px;
    @include mobile {
      width: 100%;
      padding-left: 0;
      padding-top: 20px;
    }

    .button {
      padding-top: 20px;
      padding-bottom: 30px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .title {
    margin-bottom: 10px;
  }

  .note {
    button {
      color: inherit;
      font-family: inherit;
      text-decoration: underline;
    }
  }
}

.list {
  li + li {
    padding-top: 10px;
  }
}
